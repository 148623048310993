@use "styles/globals.scss" as *;
.placeholder {
  width: 100%;
  height: 100%;
  padding: $margin;
  border-radius: 12px;

  @include cssVar(background-color, primary-accent);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain !important; // stylelint-disable-line
  }
}
