@use "styles/globals.scss" as *;
.promoBanner {
  margin-top: $margin / 2;
  margin-bottom: $margin * 2;
  width: 100%;
  display: flex;
  justify-content: center;

  @include mobile {
    margin-bottom: 0;
  }

  .inner {
    border-radius: 12px;
    display: grid;
    grid-template-columns: auto 104px;
    padding: $margin;
    transition: 0.2s all ease-in-out;
    align-items: center;

    @include mobile {
      display: flex;
      padding: 0;
    }

    .image {
      display: flex;
      justify-content: flex-end;
      max-width: 482px;
      width: 100%;
      height: 114px;

      img {
        height: 100%;

        @include mobile {
          max-height: 100%;
        }
      }
    }
  }
}
