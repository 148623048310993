@use "styles/globals.scss" as *;
.categoriesGrid {
  @include mobile {
    margin: 0 calc(-#{$margin});
  }

  .title {
    text-align: center;
    padding-bottom: $margin;

    @include fontHeadingLarge;
    @include cssVar(color, primary-dark);

    @include mobile {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 0 $margin;
      font-size: 1.25rem;
    }
  }

  .categories {
    display: grid;
    justify-content: center;
    grid-auto-flow: column;
    grid-column-gap: $margin * 3;

    @include mobile {
      grid-column-gap: $margin * 2;
      padding: 0 $margin;
    }

    &.overflow {
      @include overflowSlider();

      > * {
        width: 125px;
        height: 130px;
      }
    }
  }

  .scroller {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
  }

  &.slider {
    > div {
      @include overflowSlider();

      grid-gap: 18px;
    }
  }

  &.whiteText {
    @include cssVar(color, tertiary-white);

    .title {
      @include cssVar(color, tertiary-white);
    }

    svg path {
      @include cssVar(fill, tertiary-white);
    }
  }
}
