@use "styles/globals.scss" as *;
.home {
  display: block;

  .hero {
    position: absolute;
    min-height: 698px;
    width: 100%;
    z-index: -1;
    clip-path: polygon(0 0, 100% 0, 100% 77.35%, 0% 100%);
    left: 0;

    @include mobile {
      min-height: 300px;
      height: 300px;
      clip-path: none;
    }

    @include max-width-breakpoint(746px) {
      height: 330px;
    }

    @include max-width-breakpoint(625px) {
      height: 380px;
    }

    @include max-width-breakpoint(414px) {
      height: 420px;
    }

    @include max-width-breakpoint(400px) {
      height: 450px;
    }

    @include max-width-breakpoint(362px) {
      height: 490px;
    }

    @include max-width-breakpoint(296px) {
      height: 520px;
    }

    .gradient {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      mix-blend-mode: color;
      $accentH: var(--colour-primary-accent-h);
      $accentS: var(--colour-primary-accent-s);
      $accentL: var(--colour-primary-accent-l);

      background-image: linear-gradient(
        300deg,
        hsl(calc(#{$accentH} - 16), $accentS, calc(#{$accentL} + 3%)),
        hsl(calc(#{$accentH} - 9), $accentS, calc(#{$accentL} + 2%)) 50%,
        var(--colour-primary-accent) 89.32%
      );
    }

    .backgroundImage {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 50%;
      filter: saturate(0);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.relative {
      position: relative;
      z-index: 1;
    }

    .pattern {
      position: absolute;
      top: -200px;
      min-height: 100%;
      height: 145%;
      width: 100%;
      transform: scaleX(-1);
      background-blend-mode: overlay(60%);
      overflow: hidden;

      svg {
        width: 120%;
        position: relative;
        right: 100px;

        g rect {
          &:first-child {
            @include cssVar(fill, primary-accent);
          }
        }
      }
    }

    &.loggedIn {
      min-height: 120px;
      clip-path: none;
      overflow: hidden;

      @include mobile {
        height: 120px;
      }
    }
  }

  .body {
    margin: auto;

    @include maxContentWidth;

    .top {
      padding-top: 103px;
      position: relative;
      z-index: 2;
      margin: auto;
      display: flex;
      justify-content: space-between;
      max-width: 1190px;

      .text {
        max-width: 612px;

        h1 {
          font-size: 3em;
        }

        h3 {
          font-size: 1.5rem;
          font-weight: normal;
        }
      }

      @include mobile {
        display: grid;
        padding-top: 8px;

        .text {
          max-width: 100%;
          font-size: 1.25rem;

          h1 {
            font-size: 2.25em;
          }

          h3 {
            font-size: 1.125rem;
            font-weight: normal;
          }
        }
      }

      .mobileSignin {
        margin-top: $margin / 2;

        a {
          height: 60px;
          margin-bottom: $margin / 2;
          font-size: 1.25rem;

          &.activate {
            height: auto;
            font-size: 1.125rem;

            @include cssVar(color, tertiary-white);
          }
        }
      }
    }

    .categoriesGrid {
      padding-top: 56px;
      padding-bottom: $margin * 2;
    }

    h3 {
      svg:hover {
        cursor: pointer;
      }
    }

    .grid {
      margin: $margin * 2 0;
    }

    @include cssVar(color, tertiary-white);

    &.loggedIn {
      .top {
        padding: 0;
        height: 120px;
        display: flex;
        align-items: center;

        h1 {
          @include fontHeadingXL;
        }

        @include mobile {
          height: 60px;
        }
      }
    }
  }

  .neatIdeasLink {
    color: white;
    font-weight: bold;
  }
}
