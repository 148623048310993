@use "styles/globals.scss" as *;
.newsCard {
  display: grid;
  max-width: 424px;
  min-height: 400px;
  width: 100%;

  h2 {
    @include fontHeadingMedium;
  }

  @include mobile {
    min-height: 250px;
  }

  .hero {
    max-width: 100%;
    height: 264px;

    @include mobile {
      height: max-content;
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include mobile {
        height: 180px;
      }

      img,
      div {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }

  .body {
    * {
      @include fontBodyMedium;
    }

    @include mobile {
      display: none;
    }
  }

  .newsTag {
    margin: $margin / 2 0;

    svg {
      path {
        @include cssVar(fill, tertiary-pricesavings);
      }
    }
  }

  .eventTag {
    svg {
      path {
        @include cssVar(fill, tertiary-accent);
      }
    }
  }
}
