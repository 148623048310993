@use "styles/globals.scss" as *;
.offerCard {
  width: 100%;
  max-width: 424px;
  min-height: 400px;

  @include mobile {
    min-height: 370px;
  }

  .image {
    height: 264px;
    object-fit: cover;
    width: 100%;
    position: relative;

    @include mobile {
      height: 180px;
    }

    img,
    div {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
    }
  }

  .body {
    padding-top: 15px;
    display: grid;
    grid-gap: $margin / 2;

    h3 {
      font-size: 1.125rem;

      @include cssVar(color, primary-dark);
    }

    .popular {
      font-size: 0.875rem;

      svg path {
        @include cssVar(fill, primary-accent);
      }
    }

    .ending {
      svg {
        path {
          @include cssVar(fill, tertiary-error);

          &:nth-child(3) {
            fill: none;

            @include cssVar(stroke, tertiary-error);
          }
        }

        circle {
          @include cssVar(stroke, tertiary-error);
        }
      }
    }

    .address {
      display: flex;
      align-items: center;
      font-size: 0.875rem;

      @include cssVar(color, primary-middle);

      > svg {
        width: 12px;
        height: 15px;
        margin-right: 7px;
        flex-shrink: 0;

        path {
          @include cssVar(stroke, primary-middle);
        }
      }
    }

    .description {
      @include fontBodyMedium;

      @include mobile {
        display: none;
      }
    }

    .discount {
      @include cssVar(color, tertiary-pricesavings);
      @include fontBodyLarge;

      p {
        font-size: 1.125rem;
      }

      strong {
        @include fontHeadingLarge;
      }
    }

    .price {
      @include cssVar(color, tertiary-pricesavings);
      @include fontHeadingLarge;

      span {
        text-decoration: line-through;
        font-weight: normal;

        @include cssVar(color, primary-middle);
      }

      .soldOut {
        color: $red;
      }
    }
  }

  &.small {
    .body {
      h3 {
        @include fontHeadingMedium2;
      }

      .description {
        display: none;
      }

      .price {
        display: none;
      }
    }
  }

  .heart {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    padding: 0;
    padding-top: 2px;
    position: absolute;
    right: $margin;
    bottom: -18px;
    cursor: pointer;
    transition: 0.2s all ease-in-out;

    @include cssVar(background-color, tertiary-white);
    @include dropShadow;

    svg {
      width: 18px;
      height: 18px;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .productCount {
    p {
      @include cssVar(color, primary-middle);
      @include fontBodyMedium;
    }
  }
}
