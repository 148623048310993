@use "styles/globals.scss" as *;
.signInBlock {
  height: 100%;
  width: 444px;
  display: flex;
  flex-direction: column;
  padding: 37px $margin 49px;
  border-radius: 12px;
  box-shadow: 0 0 16px #00000029;

  h3 {
    text-align: center;
    margin-bottom: $margin / 2;
    font-size: 1.5rem;
  }

  label {
    font-size: 0.875rem;
  }

  input {
    height: 96px;
    padding: $margin * 2 20px $margin;
    margin-bottom: $margin / 2;

    @include fontBodyXL;
  }

  .passwordBlock {
    transition: height 250ms ease-in-out, opacity 200ms ease-in;
    transition-delay: 50ms;
    background-color: transparent;
    opacity: 1;

    &:not(.visible) {
      height: 0;
      opacity: 0;
      pointer-events: none;

      > div {
        background-color: transparent;
      }

      input {
        padding: 0;
        height: 0;
        border: transparent;
      }

      label {
        display: none;
      }
    }
  }

  .button {
    margin-bottom: $margin / 2;

    button {
      width: 100%;
    }
  }

  .spinner {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 60px; // same as login button
  }

  p {
    text-align: center;
    margin: 0;
    font-size: 1rem !important; // stylelint-disable-line declaration-no-important

    @include fontBodyLarge;

    a {
      @include cssVar(color, primary-highlight);
    }
  }

  hr {
    border-top: 1px solid;
    height: 1px;

    @include cssVar(border-top-color, secondary-ui-middle);
  }

  .socialLogin {
    border-top: solid 1px;
    margin-top: 22.5px;
    padding-top: 16px;

    h3 {
      font-size: 1.25rem !important; // stylelint-disable-line declaration-no-important
      margin-bottom: 17px;
    }

    .buttons {
      > a,
      button {
        width: 100%;
        font-size: 1.125rem;
      }
    }

    @include cssVar(border-top-color, secondary-ui-middle);
  }

  .error {
    display: flex;
    flex-direction: column;
    margin: 0 0 $margin * 0.5;
    padding: 12px 8px;
    color: $colorTertiaryError;
    border: 2px solid $colorTertiaryError;
    border-radius: 8px;

    h3 {
      margin: 0;

      @include fontHeadingSmall;
    }

    p {
      height: fit-content;

      @include fontBodyMedium;
    }
  }

  @include cssVar(color, primary-dark);
  @include cssVar(background-color, tertiary-white);
}
