@use "styles/globals.scss" as *;
.competitionCard.competitionCard.competitionCard {
  display: grid;
  max-width: 424px;
  min-height: 400px;
  width: 100%;
  margin-bottom: $margin;

  @include mobile {
    min-height: 250px;
  }

  .hero {
    max-width: 100%;
    height: 264px;

    @include mobile {
      height: 180px;
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-bottom: 10px;

      img,
      div {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        object-fit: cover;
      }
    }
  }

  h2 {
    margin-top: 10px;

    @include fontHeadingMedium;
  }

  p {
    margin-top: 10px;
    margin-bottom: 0;

    @include fontBodyMedium;
  }

  .competitionTag {
    svg {
      path {
        @include cssVar(fill, primary-highlight);
      }
    }
  }
}
