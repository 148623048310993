@use "styles/globals.scss" as *;
.cardGrid {
  @include mobile {
    margin: 0 calc(-#{$margin});
  }

  .title {
    padding-bottom: $margin;

    @include fontHeadingLarge;
    @include cssVar(color, primary-dark);

    @include mobile {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: $margin;
      font-size: 1.25rem;
    }

    svg {
      display: none;

      @include mobile {
        display: block;
        height: 13px;
        width: 11px;
      }
    }
  }

  .entries {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $margin;

    @include mobile {
      grid-gap: $margin / 2;
      padding: 0 $margin;
    }

    > a {
      justify-self: center;
    }

    &:not(&.slider) {
      @include mobile {
        grid-template-columns: 1fr;
      }
    }

    &.slider {
      @include overflowSlider();
    }

    &.small {
      > a {
        min-width: 180px;
        max-width: 180px;
      }
    }
  }

  .scroller {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
  }

  &.whiteText {
    .title {
      @include cssVar(color, tertiary-white);
      @include fontHeadingLarge;

      svg path {
        @include cssVar(fill, tertiary-white);
      }
    }

    @include cssVar(color, tertiary-white);
  }
}
