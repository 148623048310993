@use "styles/globals.scss" as *;
$accentH: var(--colour-primary-accent-h);
$accentS: var(--colour-primary-accent-s);
$accentL: var(--colour-primary-accent-l);
$utility: hsl(calc(#{$accentH} - 19), $accentS, $accentL);

.categoryButton {
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: 135px;
  text-align: center;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 5px;

  &:hover {
    .title {
      transform: translateY(5px);
    }

    .image {
      img {
        filter: brightness(0) invert(1);
      }

      &::before {
        transform: scale(1.3);
        opacity: 1;
      }
    }
  }

  .image {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
    min-height: 96px;
    min-width: 96px;

    img {
      transition: 0.2s all ease-in-out;
    }

    div {
      width: 96px;
      height: 96px;
      position: relative;
    }

    &::before {
      content: '';
      width: 100px;
      height: 100px;
      position: absolute;
      border-radius: 50%;
      background: $utility;
      opacity: 0.1;
      transition: 0.2s all ease-in-out;
    }
  }

  .title {
    margin-top: $margin;
    transition: 0.2s all ease-in-out;
  }

  div {
    @include fontHeadingSmall;

    font-size: 1rem;
  }
}
